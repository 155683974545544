import React, { Fragment } from 'react'
import {ResumeProjectsType} from "../types";
import {List, Header, Grid} from 'semantic-ui-react'
import shortid from 'shortid';
import DateDisplay from "./DateDisplay";
import styles from './EmployerProject.module.css';
import {startCase} from 'lodash';
import printStyles from '../print.module.css';

const ResumeContent = ({contextObject, contextKey, limit}: { contextObject: any, contextKey: string, limit: number }) => {
    return limit > 0 && contextObject[contextKey] ? (
        <Fragment>
            <Grid.Column tablet={16} computer={2}>
                <Header size='tiny' content={startCase(contextKey)}/>
            </Grid.Column>
            <Grid.Column width={1}/>
            <Grid.Column tablet={14} computer={13}>
                <List >
                {(contextObject[contextKey] || []).slice(0, limit).map((obj: string) => (
                    <List.Item key={shortid.generate()}  className={styles.noBreakInside}>
                        <span dangerouslySetInnerHTML={{ __html: obj }}/>
                    </List.Item>
                ))}
                </List>
            </Grid.Column>
        </Fragment>
    ) : (<Fragment/>)
}

const EmployerProject = (resumeProject: ResumeProjectsType) => {
    let {longName, startDate, endDate, employerNames} = resumeProject
    return (
        <Grid  className={printStyles.noBreakInside}>
            <Grid.Row>
                <Grid.Column width={10} ><Header size='small' as='span' >{longName}</Header></Grid.Column>
                <Grid.Column width={6} floated='right' textAlign='right'>
                    <DateDisplay startDate={startDate} endDate={endDate}/>
                </Grid.Column>
                <Grid.Column width={10}>
                    {resumeProject.roles.slice(0,1).map(role => {
                        return (<span key={role} >{role}</span>);
                    })}
                </Grid.Column>
                <Grid.Column width={6} floated='right' textAlign='right'>
                    {employerNames[resumeProject.employerName]}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <ResumeContent limit={resumeProject.limit}
                               contextObject={resumeProject}
                               contextKey='objective'/>
                <ResumeContent limit={resumeProject.limit}
                               contextObject={resumeProject}
                               contextKey='responsibilities'/>
                {(!resumeProject.objective || resumeProject.limit > 2)
                && (<ResumeContent
                        limit={resumeProject.limit}
                        contextObject={resumeProject}
                        contextKey='achievements'
                    />)}

            </Grid.Row>
        </Grid>
    )
}

export default EmployerProject
