import React, { Fragment } from 'react'
import { Moment } from 'moment';
import {Epoch} from "../types";
import styles from "./DateDisplay.module.css";

const moment = require("moment");

const EpochDate = ({mDate}:{mDate:Moment}) => {
    return (<span hidden={!mDate.isValid()}>{mDate.format('MMM. YYYY')}</span>)
}

const DateDisplay = (epoch: Epoch) => {
    const mStartDate = moment(epoch.startDate);
    const mEndDate:Moment = moment(epoch.endDate);
    return (
        <Fragment>
            <span className={styles.dateDisplay}>
                <EpochDate mDate={mStartDate}/>
                <span>{' - '}</span>
                {mEndDate.isValid() ? (
                    <EpochDate mDate={mEndDate}/>
                ) : (
                    <span>Present</span>
                )}
            </span>
        </Fragment>
    )
}
export default DateDisplay
