import React from "react";
import {ResumeEmployersStoreType} from '../types'
import {Item} from "semantic-ui-react";
import Employer from "./Employer";


const Employers = ({employers}:ResumeEmployersStoreType) => {
    return (
        <Item.Group unstackable relaxed>
            { employers.map(employer => (
                <Employer key={employer.website} {...employer} />
            )) }
        </Item.Group>
    )
}

export default Employers;
