import {
  ReduxStoreType,
  SetResumeSummary
} from "../types";

export const summary = (
    state: ReduxStoreType['summary'] = {
      "profileImageUrl": '',
      "name": '',
      "nameSmall": '',
      "title": '',
      "titleSmall": '',
      "linkedInUrl": ''
    },
    action: SetResumeSummary
): ReduxStoreType['summary'] => {
  switch (action.type) {
    case "resume/set-summary":
      return action.payload ? action.payload.summary : state
    default:
      return state;
  }
}

export default summary
