import { connect } from 'react-redux'

import Employers from '../components/Employers'
import {ResumeEmployersStoreType} from "../types";

const mapStateToProps = (state: ResumeEmployersStoreType) => {
    return {
        employers: state.employers || [],
    };
}

const ResumeEmployersContainer = connect(
    mapStateToProps,
)(Employers)

export default ResumeEmployersContainer

