import React, { Fragment }  from "react";
import {ResumeEmployerType} from '../types'
import {Button, Icon, Item} from "semantic-ui-react";
import {resolveUrl} from "../sagas/api";
import DateDisplay from "./DateDisplay";


const Employer = (props:ResumeEmployerType) => {
    const {
        logo,
        linkedin,
        description,
        website} = props
    return (
        <Fragment>
            <Item>
                <Item.Image  src={resolveUrl(logo)} size="tiny" />
                <Item.Content>
                    <Item.Header>{description}</Item.Header>
                    <Item.Meta>
                        <DateDisplay {...props} />
                    </Item.Meta>
                    <Item.Extra>
                        <Button color='linkedin' as="a"
                                href={linkedin}
                                compact
                                size="mini"
                                target='_blank'>
                            <Icon name='linkedin' /> LinkedIn
                        </Button>
                        <Button as="a"
                                href={website}
                                compact
                                size="mini"
                                target='_blank'>
                            <Icon name='world' />
                            {website.split('/').find(s => s.startsWith('www'))}
                        </Button>
                    </Item.Extra>
                </Item.Content>
            </Item>
        </Fragment>
    )
}

export default Employer;
