import { connect } from 'react-redux'

import EmployerProjects from '../components/EmployerProjects'
import {ReduxStoreType} from "../types";
import {fromPairs} from 'lodash';

const mapStateToProps = (state: ReduxStoreType, ownProps: { "employerName": string; }) => {
    const employerProjects = state.employers.map(e =>
        state.projects.filter(project => project.employerName === e.name) || []
    );
    return {
        detailLevel: state.settings.detailLevel,
        projects: employerProjects.flat(),
        employerNames: fromPairs(state.employers.map(employer => [employer.name, employer.description]))
    };
}

const ResumeEmployersContainer = connect(
    mapStateToProps,
)(EmployerProjects)

export default ResumeEmployersContainer

