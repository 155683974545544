import {
    Action,
    GetEmployerProjects,
    GetResumeEmployers, GetResumeSettings,
    GetResumeSummary,
    PayloadedAction, SetEmployerProjects,
    SetResumeEmployers, SetResumeSettings,
    SetResumeSummary,
} from "./types";

function createPayloadedAction<
    TAction extends PayloadedAction<TAction["type"], TAction["payload"]>
    >(
    type: TAction["type"]
): (
    payload: TAction["payload"]
) => PayloadedAction<TAction["type"], TAction["payload"]> {
    return (payload: TAction["payload"]) => ({
        type: type,
        payload
    });
}

export function createAction<TAction extends Action<TAction["type"]>>(
    type: TAction["type"]
): () => Action<TAction["type"]> {
    return () => ({
        type: type
    });
}

export const setResumeSettings = createPayloadedAction<SetResumeSettings>("resume/set-settings");
export const getResumeSettings = createAction<GetResumeSettings>("resume/get-settings");

export const setResumeSummary = createPayloadedAction<SetResumeSummary>("resume/set-summary");
export const getResumeSummary = createAction<GetResumeSummary>("resume/get-summary");

export const getResumeEmployers = createAction<GetResumeEmployers>("resume/get-employers");
export const setResumeEmployers = createPayloadedAction<SetResumeEmployers>("resume/set-employers");

export const getEmployerProjects = createPayloadedAction<GetEmployerProjects>("resume/get-projects");
export const setEmployerProjects = createPayloadedAction<SetEmployerProjects>("resume/set-projects");
