import React, { Fragment } from 'react'
import {ResumeProjectsType} from "../types";
import EmployerProject from './EmployerProject';

interface Props {
    "employerName": string;
    employerNames: any;
    detailLevel: number;
    projects: Array<ResumeProjectsType>;
}

const EmployerProjects = ({employerNames, projects, detailLevel}:Props) => {
    return (
        <Fragment>
            {projects.map(project => (
                <EmployerProject {...project} employerNames={employerNames} limit={detailLevel} key={project.employerName + project.longName} />
            ))}
        </Fragment>
    );
}

export default EmployerProjects
