import { connect } from 'react-redux'
import * as actions from '../actions'
import ResumeSettings from '../components/ResumeSettings'
import {ResumeSettingsStoreType, ResumeSettingsType} from "../types";

const mapStateToProps = (state: ResumeSettingsStoreType) => {
    return {
        detailLevel: state.settings.detailLevel
    };
}
const mapDispatchToProps = (dispatch:Function) => {
    return {
        updateSettings: (v:any) => {
            dispatch(actions.setResumeSettings({
                settings: {
                    detailLevel: v
                }
            }))
        }
    }
}
const ResumeSettingsContainer = connect(
    mapStateToProps, mapDispatchToProps,
)(ResumeSettings)

export default ResumeSettingsContainer

