import { connect } from 'react-redux'

import ResumeSummary from '../components/ResumeSummary'
import { ResumeSummaryStoreType } from "../types";

const mapStateToProps = (state: ResumeSummaryStoreType) => {
    return state.summary;
}

const ResumeSummaryContainer = connect(
    mapStateToProps,
)(ResumeSummary)

export default ResumeSummaryContainer

