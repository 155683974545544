import {
    ReduxStoreType,
    SetResumeSettings
} from "../types";

export const summary = (
    state: ReduxStoreType['settings'] = {
        detailLevel: 0,
    },
    action: SetResumeSettings
): ReduxStoreType['settings'] => {
    switch (action.type) {
        case "resume/set-settings":
            return action.payload ? action.payload.settings : state
        default:
            return state;
    }
}

export default summary
