import React from "react";
import { ResumeSummaryType } from '../types'
import {Placeholder, Item, Grid, List,} from 'semantic-ui-react'
import LinkedInLink from "./LinkedInLink";

import styles from './ResumeSummary.module.css';

const SummaryItem = ({ profileImageUrl, linkedInUrl, name, title}: ResumeSummaryType) => (
    <Item>
        <Item.Image size={'tiny'} avatar src={profileImageUrl} className={styles.noPrint} />
        <Item.Content verticalAlign='middle' >
            <Item.Header>{name}</Item.Header>
            <Item.Meta>{title}</Item.Meta>
            <Item.Extra className={styles.noPrint}>
                <LinkedInLink className='item' href={linkedInUrl} />
            </Item.Extra>
        </Item.Content>
    </Item>
)

const LoadingItem = () => (
    <Item>
        <Item.Content  verticalAlign='middle'>
            <Placeholder>
                <Placeholder.Header image>
                    <Placeholder.Line length='short' />
                    <Placeholder.Line length='very short' />
                </Placeholder.Header>
            </Placeholder>
        </Item.Content>
    </Item>
)


const ResumeSummary = ({  profileImageUrl, linkedInUrl, name, title }:ResumeSummaryType) => {

    return (
        <Item.Group relaxed >
            { (name && name.length > 0)
                ? (<SummaryItem profileImageUrl={profileImageUrl} linkedInUrl={linkedInUrl} name={name} title={title} />)
                : (<LoadingItem />)
            }
        </Item.Group>
    )
}


export default ResumeSummary;
