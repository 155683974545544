import React from 'react'
import {Icon} from "semantic-ui-react";
import styles from './LinkedInLink.module.css';

const LinkedInLink = ({className, href, disabled = (typeof href === 'undefined')}:{ className: string, href?: string, disabled?: boolean }) => (
  <a color='linkedin '
       target='_blank'
       className={className + ' ' + styles.link}
       href={ href ? href : 'javascript:void(0);' }
       aria-disabled={disabled}
       >
        <Icon disabled={disabled} name='linkedin' />LinkedIn
    </a>
)

export default LinkedInLink
