import {
    ReduxStoreType, SetEmployerProjects,
} from "../types";

export const projects = (
    state: ReduxStoreType['projects'] = [],
    action: SetEmployerProjects
): ReduxStoreType['projects'] => {
  switch (action.type) {
    case 'resume/set-projects':
      return state.concat(action.payload ? action.payload.projects : []);
    default:
      return state;
  }
}

export default projects
