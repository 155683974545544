import {
  ReduxStoreType,
  SetResumeEmployers,
} from "../types";

export const employers = (
    state: ReduxStoreType['employers'] = [],
    action: SetResumeEmployers
): ReduxStoreType['employers'] => {
  switch (action.type) {
    case "resume/set-employers":
      return action.payload ? action.payload.employers : state
    default:
      return state;
  }
}

export default employers
