
import { put, takeLatest, call, all } from 'redux-saga/effects'
import { Apis, fetchApi, resolveUrl } from './api'
import * as actions from '../actions'

import { mapValues, fromPairs, assign, cloneDeep, replace } from 'lodash';
import {
  ResumeEmployersStoreType,
  ResumeEmployerType,
  ResumeProjectsStoreType,
  ResumeSummaryStoreType
} from "../types";



export function* fetchProjects(employer: ResumeEmployerType) {
  const json:ResumeProjectsStoreType = yield call(fetchApi, {
    'path': employer.self
  })
  yield put(actions.setEmployerProjects({
    projects: json.projects.map(project => {
      project.employerName = employer.name;
      return project;
    })
  }));
}

export function* fetchResumeSummary() {
  const json:ResumeSummaryStoreType = yield call(fetchApi, Apis.summary)
  json.summary.profileImageUrl = yield resolveUrl(json.summary.profileImageUrl)
  yield put(actions.setResumeSummary(json));
}

export function* fetchResumeEmployers() {
  const json: ResumeEmployersStoreType = yield call(fetchApi, Apis.employers)
  yield all(json.employers.map(employer => call(fetchProjects, employer)))
  yield put(actions.setResumeEmployers(json));
}

export default function* rootSaga() {
  yield takeLatest(actions.getResumeSummary().type, fetchResumeSummary)
  yield takeLatest(actions.getResumeEmployers().type, fetchResumeEmployers)
}
