import {createStore, applyMiddleware, compose, Reducer, combineReducers, } from 'redux'
import createSagaMiddleware, { END, Saga } from 'redux-saga'

import logger from 'redux-logger'
import {ReduxStoreType,} from "../types";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const initialState: ReduxStoreType = Object.seal({
    employers: [],
    projects: [],
    summary: {
        "profileImageUrl": '',
        "name": '',
        "nameSmall": '',
        "title": '',
        "titleSmall": '',
        "linkedInUrl": '',
    },
    settings: {
        detailLevel: 7
    }
});

export default function configureStore(rootReducer: Reducer<ReduxStoreType>) {

    const sagaMiddleware = createSagaMiddleware()
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    let store = createStore(
        rootReducer,
        initialState,
        composeEnhancers(
            applyMiddleware(sagaMiddleware, logger),
        )
    );
  return {
      ...store,
      runSaga: (saga: Saga, ...args: any) => sagaMiddleware.run(saga, ...args),
      close: () => store.dispatch(END)
  }
}
