import * as React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from "react-redux";
import {Route, Switch, HashRouter as Router, Redirect, NavLink} from 'react-router-dom'



import rootSaga from './sagas'
import rootReducer from './reducers'
import configureStore from './store/configureStore'
import styles from './print.module.css';

import * as actions from './actions'
import {Grid, Container, Item, Segment} from "semantic-ui-react";
import ResumeSummaryContainer from "./containers/ResumeSummaryContainer";
import ResumeEmployersContainer from "./containers/ResumeEmployersContainer";
import ResumeProjectsContainer from "./containers/EmployerProjectsContainer";
import ResumeSettingsContainer from "./containers/ResumeSettingsContainer";

const store = configureStore(rootReducer)
store.runSaga(rootSaga)
store.dispatch(actions.getResumeSummary())
store.dispatch(actions.getResumeEmployers())

function render() {
  ReactDOM.render(
      <Router>
        <Provider store={store}>
            <Container className={styles.container}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column><ResumeSummaryContainer/></Grid.Column>
                    </Grid.Row>
                    <Grid.Row className={styles.noPrint}>
                        <Grid.Column>
                            <div className='ui secondary pointing menu'>
                                <NavLink className='item' activeClassName='active' to="/" exact>Work Summary</NavLink>
                                <NavLink className='item' activeClassName='active' to="/employers">Employer History</NavLink>
                                <NavLink className='item right' activeClassName='active' to="/settings"><i className="wrench icon"></i></NavLink>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column stretched>
                            <Segment basic>
                                <Switch>
                                    <Route path="/settings" exact component={ResumeSettingsContainer} />
                                    <Route path="/employers" exact component={ResumeEmployersContainer} />
                                    <Route path="" component={ResumeProjectsContainer} />
                                </Switch>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </Provider>
      </Router>,
    document.getElementById('root'),
  )
}

render()
store.subscribe(render)
