import React, {Fragment} from "react";
import {ResumeSettingsStoreType, ResumeSettingsType} from '../types'
import {Button, Checkbox, Form, Item} from "semantic-ui-react";
import Employer from "./Employer";

const ResumeSettings = ({detailLevel, updateSettings}:ResumeSettingsType & {updateSettings: Function;}) => {
    return (
        <Fragment>
            <Form>
                <Form.Input
                    label='Detail Level'
                    type='number'
                    value={detailLevel}
                    onChange={(param,data) => updateSettings(data.value)}
                    min={0}
                    max={10}
                    inline
                />
            </Form>
        </Fragment>
    )
}

export default ResumeSettings;
