import {ApiType} from "../types";

const apiUrlBase = "https://cjlyth.gitlab.io/resume-data/v2";


// Compose a URL from an API path
const buildUrl = (path: string) => apiUrlBase + '/' + path

// If the url starts with 'http' just use it otherwise use the API url
export const resolveUrl = (uri: string) => uri.startsWith('http') ? uri : buildUrl(uri)

// handling requests and responses
const extractJson = (response: { json: Function }) => response.json()
const logError = (path: string) => (e: any) => console.log('Error calling the API', path, e)
const fetchJson = (path: string) => fetch(path, ).then(extractJson,).catch(logError(path))



export const Apis: {
    summary: ApiType;
    employers: ApiType;
} = {
    'summary': {
        'path': 'summary.json'
    },
    'employers': {
        'path': 'employers.json',
    },
};

export const fetchApi = (api: ApiType): object => {
    return fetchJson(resolveUrl(api.path))
}
