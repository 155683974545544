import { combineReducers, Reducer } from 'redux'
import summary from './summary'
import settings from './settings'
import employers from './employers'
import projects from './projects'
import {ReduxStoreType} from "../types";

export const rootReducer: Reducer<ReduxStoreType> = combineReducers<ReduxStoreType>({
  summary, employers, projects, settings,
})

export default rootReducer
